.App {
  text-align: center;
}

.tab-strip__tab {
  padding: 0.7em 2em;
  margin: 0;
  border: solid 1px #e8e8e8;
  outline: none;
  background: #e8e8e8;
}

.tab-strip__tab--active {
  background: #222222;
  color: white;
}

body {
  background-color: #222;
  color: white;
}
table {
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid white;
}

.react-logo {
  height: 55px;
}

.backendless-logo {
  height: 50px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
  overflow: auto;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
